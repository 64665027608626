import { useEffect } from "react";
import {useGoogleLogin} from '@react-oauth/google';
import axios from 'axios';

function OAuthFrom() {  
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const company_id = urlParams.get('company_id');
  const porta_api = urlParams.get('porta_api');
  const api_key = urlParams.get('api_key');
  const client_id = urlParams.get('client_id');
  const client_secret = urlParams.get('client_secret');
    
  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => {
        const auth_code = tokenResponse.code;
        
        axios.post("https://www.googleapis.com/oauth2/v4/token", {
          code: auth_code,
          client_id: client_id,
          client_secret: client_secret,
          redirect_uri: window.location.origin, //"postmessage",
          grant_type: "authorization_code"
        })
        .then((data) => {
            const access_token = data.data.access_token;
            const refresh_token = data.data.refresh_token;
            
            //ajax from https to http not allowed
            /*axios.post(porta_api+'/api/google-oauth?api_key='+api_key, {
              company_id: company_id,
              access_token: access_token,
              refresh_token: refresh_token
            })
            .then((data) => {  
              window.close();
            });*/
            
            const w = 300;
            const h = 100;
            const left = (window.screen.width/2)-(w/2);
            const top = (window.screen.height/2)-(h/2);
            
            window.open(porta_api+'/api/google-oauth?api_key='+api_key+'&company_id='+company_id+'&access_token='+access_token+'&refresh_token='+refresh_token, '_oauth', 'width='+w+',height='+h+',top='+top+',left='+left);
            window.close();
        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
                if (error.response.data.error && error.response.data.error.message) {
                    console.log(error.response.data.error.message);
                }
            }
        });
    },
    //flow: 'implicit',
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive'    
  });
  
  useEffect(() => {    
    //delete on Porta instead
    /*axios.post(porta_api+'/api/delete-google-oauth?api_key='+api_key, {
      company_id: company_id,
    })
    .then((data) => {
    });*/
    setTimeout(function() {       
      if (document.getElementById('oauth'))
        document.getElementById('oauth').click();
    }, 1000);    
  }, [porta_api, api_key, company_id]);
     
  return (
    <div id={'oauth'} onClick={() => googleLogin()} style={{marginTop:'30px', color:'#0b57d0', fontWeight: 'bold'}}>Authenticating...<br/>Please Allow Popups.</div>
  );
}

export default OAuthFrom;
