import './App.css';
import {GoogleOAuthProvider} from '@react-oauth/google';
import OAuthForm from "./OAuthForm";

function App() {     
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const client_id = urlParams.get('client_id');

  return (
    <div className="App">
      <GoogleOAuthProvider clientId={client_id}>
        <OAuthForm />
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
